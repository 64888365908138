<template>
  <div class="stats app-container">
    <div class="page-title">
      <h2>{{ $t('subscription.subscriptions') }} {{ query.is_paired_exchange==1? ' - Paired Exchange' : rolesLabel[query.type] }} </h2>
    </div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card v-if="checkRole(['Admin'])" class="box-card filter-card just-for-admin">
          <div slot="header" class="clearfix">
            <span>{{ $t('general.filter') }}</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
          </div>
          <div class="filter-container">
            <el-radio v-model="activeList" label="actives" border @change="getSubSubscriptionsList('indexactive')">{{ $t('general.F_actives') }}</el-radio>
            <el-radio v-model="activeList" label="desactives" border @change="getSubSubscriptionsList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
            <el-radio v-model="activeList" label="disponibles" border @change="handleFilterSubscriptions">{{ $t('general.disponibles') }}</el-radio>
            <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubSubscriptionsList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
          </div>
        </el-card>
        <el-row>
          <el-col :span="12">
            <vs-card class="success-card" @click="havingType('Donor')">
              <template #title>
                <h3>{{ $t('general.donors') }}</h3>
              </template>
              <template #text>
                <h2><count-to :start-val="0" :end-val="stats.donors" :duration="3000" class="card-panel-num" /></h2>
              </template>
            </vs-card>
          </el-col>
          <el-col :span="12">
            <vs-card class="warning-card" @click="havingType('Recipient')">
              <template #title>
                <h3>{{ $t('general.recipients') }}</h3>
              </template>
              <template #text>
                <h2><count-to :start-val="0" :end-val="stats.recipients" :duration="3000" class="card-panel-num" /></h2>
              </template>
            </vs-card>
          </el-col>
          <el-col :span="12">
            <vs-card class="default-card" @click="isParedExchange()">
              <template #title>
                <h3>{{ $t('general.paredExchange') }}</h3>
              </template>
              <template #text>
                <h2><count-to :start-val="0" :end-val="stats.paired_exchange" :duration="3000" class="card-panel-num" /></h2>
              </template>
            </vs-card>
          </el-col>
        </el-row>

      </el-col>
      <el-col :span="16">
        <el-card class="box-card">
          <div class="filter-container">
            <el-input v-model="query.keyword" clearable :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterSubscriptions" />
            <el-select v-model="query.type" clearable class="filter-item wd-200 mr-15 filter-item" :placeholder="$t('general.type')" style="width: 100%;" @change="handleFilterSubscriptions">
              <el-option v-for="(type, index) in types" :key="index" :label="type | uppercaseFirst" :value="type" />
            </el-select>
            <el-select v-model="query.organ" clearable class="filter-item wd-200 mr-15 filter-item" :placeholder="$t('subscription.organ')" style="width: 100%;" @change="handleFilterSubscriptions">
              <el-option v-for="(organ, index) in organs" :key="index" :label="organ | uppercaseFirst" :value="organ" />
            </el-select>
            <el-select v-model="query.blod_type" clearable class="filter-item wd-200 mr-15 filter-item" :placeholder="$t('subscription.blod_type')" style="width: 100%;" @change="handleFilterSubscriptions">
              <el-option v-for="(blodType, index) in blodTypes" :key="index" :label="blodType | uppercaseFirst" :value="blodType" />
            </el-select>
            <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterSubscriptions">
              {{ $t('general.search') }}
            </el-button>
            <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
              {{ $t('general.export') }}
            </el-button>
            <el-button class="mr-15 filter-item" type="primary" icon="el-icon-bell" @click="sendGeneralPushNotification">
              {{ $t('general.sendGeneralNotification') }}
            </el-button>
          </div>
          <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">

            <el-table-column align="center" :label="$t('discussion.with')" min-width="250px">
              <template slot-scope="scope">
                <div class="full-width">
                  <div class="half-photo">
                    <div class="demo-basic--circle">
                      <div>
                        <el-avatar shape="square" :size="50" :src="scope.row.avatar" />
                      </div>
                    </div>
                  </div>
                  <div class="half-right">
                    <div class="in-td">
                      <h4>{{ scope.row.name }}</h4>
                      <small>{{ scope.row.type }} ({{ scope.row.blod_type }}) </small>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column align="center" :label="$t('subscription.organ')" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.organ }}</span>
              </template>
            </el-table-column>

            <el-table-column align="center" :label="$t('general.status')" width="100">
              <template slot-scope="scope">
                <el-tag :type="scope.row.is_active ? 'success' : 'danger'">{{ scope.row.is_active ? $t('general.enabled') : $t('general.disabled') }}</el-tag>
              </template>
            </el-table-column>

            <el-table-column v-if="checkRole(['Admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
              <template slot-scope="scope">
                <el-switch
                  v-if="!scope.row.is_visible"
                  style="display: block"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="true"
                  :inactive-value="false"
                  :value="scope.row.is_visible"
                  @change="setSubscriptionStatus(scope.row.id,'visible')"
                />
              </template>
            </el-table-column>

            <el-table-column v-if="checkPermission(['edit subscription','hide subscription','delete subscription'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="150">
              <template slot-scope="scope">
                <el-button v-if="scope.row.is_visible && checkPermission(['edit subscription'])" type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="handleEditSubscription(scope.row.id);" />
                <el-button v-if="checkPermission(['hide subscription','delete subscription'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteSubscription(scope.row.id, scope.row.is_visible, scope.row.name);" />
              </template>
            </el-table-column>
          </el-table>
          <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getSubscriptionsList" />
        </el-card>
      </el-col>
    </el-row>
    <!-- ======= REGION USER DIALOG ======= -->
    <el-drawer
      :title="$t('subscription.subscription')"
      :visible.sync="dialogCreateSubscription"
      :loading="subscriptionCreating"
      size="35%"
    >
      <div class="content-inputs" style="padding:30px">
        <!--=============== USER CARD ==============-->
        <subscription-card :subscription="currentSubscription" :all="true" @sendmessage="sendmessage($event)" />
        <!--=============== END USER CARD ==============-->
        <el-divider />
        <!--=============== PAIRED CARD ==============-->
        <paired-subscription-card v-if="currentSubscription.paired!=null" :subscription="currentSubscription.paired" :all="true" />
        <!--=============== END PAIRED CARD ==============-->
        <div class="subscription-story margin-top">
          <h3>{{ $t('subscription.story') }}</h3>
          <p class="story-text" @click="test=true">
            {{ currentSubscription.story }}
          </p>
        </div>
      </div>
    </el-drawer>

    <!--el-dialog
      :title="editing ? $t('subscription.edit') + ' ' + currentSubscription.name : $t('subscription.AddSubscription')"
      :visible.sync="dialogCreateSubscription"
      :before-close="handleCloseSubscriptionCreateDraw"
      :loading="subscriptionCreating"
      width="70%"
    >
      <div class="content-inputs">
        <el-form ref="subscriptionForm" :rules="rules" :model="currentSubscription" label-position="left">
          <el-form-item :label="$t('general.type')" prop="type">
            <el-select v-model="currentSubscription.type" class="filter-item" :placeholder="$t('general.type')" style="width: 100%;" prop="type">
              <el-option
                v-for="(type, idex) in types"
                :key="idex"
                :label="type | uppercaseFirst"
                :value="type"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('subscription.organ')" prop="organ">
            <el-input v-model="currentSubscription.organ" />
          </el-form-item>
          <el-form-item :label="$t('subscription.story')" prop="story">
            <el-input v-model="currentSubscription.story" type="textarea" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="text-align:right;">
          <el-button plain type="danger" @click="dialogCreateSubscription=false">
            {{ $t('permission.cancel') }}
          </el-button>
          <el-button type="primary" :loading="subscriptionCreating" @click="editing ? updateSubscription() : createSubscription()">
            {{ subscriptionCreating ? $t('general.saving') : $t('general.save') }}
          </el-button>
        </div>
      </span>
    </el-dialog-->

    <!-- ======= REGION USER DIALOG ======= -->

    <el-dialog
      :title="$t('message.sendTo') + currentSubscription.name"
      :visible.sync="sendsms"
      width="40%"
    >
      <send-message :id="currentSubscription.user_id" :canals="[canal]" :general="generalNotif" @close="sendsms=false" />
    </el-dialog>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import subscriptionCard from './components/SubscriptionCard';
import pairedSubscriptionCard from './components/PairedSubscriptionCard';
import moment from 'moment';
import CountTo from 'vue-count-to';
import SendMessage from '../components/SendMessage';
const subscriptionResource = new Resource('subscriptions');
export default {
  name: 'SubscriptionsList',
  components: { Pagination, subscriptionCard, pairedSubscriptionCard, CountTo, SendMessage },
  directives: { waves, permission, role },
  data() {
    return {
      generalNotif: false,
      sendsms: false,
      canal: '',
      donorsCount: 0,
      recipientsCount: 0,
      list: null,
      total: 0,
      loading: false,
      downloading: false,
      subscriptionCreating: false,
      editing: false,
      activeList: 'disponibles',
      types: this.$subscriptionTypes,
      organs: this.$subscriptionOrgans,
      blodTypes: this.$subscriptionBlodTypes,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
        type: '',
        organ: '',
        blod_type: '',
      },
      stats: {
        donors: 0,
        paired_exchange: 0,
        recipients: 0,
      },
      currentSubscription: {},
      dialogCreateSubscription: false,
      currentSubscriptionId: 0,
      rules: {
        organ: [{ required: true, message: this.$t('subscription.OrganRequired'), trigger: 'blur' }],
        story: [{ required: true, message: this.$t('subscription.BodyRequired'), trigger: 'blur' }],
      },
      rolesLabel: {
        'Recipient': ' - Recipients',
        'Donor': ' - Donors',
        '': '',
      },
    };
  },
  computed: {
  },
  async created() {
    this.resetNewSubscription();
    const type = this.$route.query.type;
    if (type !== undefined && type !== ''){
      if (type === 'paired_exchange'){
        this.isParedExchange();
      } else {
        this.havingType(type);
      }
    } else {
      this.getSubscriptionsList();
    }
    this.getSubscriptionsStats('statistics');
  },
  methods: {
    checkPermission,
    checkRole,
    sendGeneralPushNotification(){
      this.canal = 'Push Notification';
      this.generalNotif = true;
      this.sendsms = true;
    },
    sendmessage(canal){
      this.generalNotif = false;
      this.canal = canal;
      this.sendsms = true;
    },
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseSubscriptionCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelSubscriptionForm() {
      this.$refs.SubscriptionCreationDrawer.closeDrawer();
    },
    havingType(type){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        type: type,
      };
      this.handleFilterSubscriptions();
    },
    isParedExchange(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        type: '',
        is_paired_exchange: 1,
      };
      this.handleFilterSubscriptions();
    },
    getTypeCount(type){
      var count = 0;
      this.list.forEach((subscription) => {
        if (subscription.type === type){
          count++;
        }
      });
      return count;
    },
    initSearch(){
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        type: '',
      };
      this.activeList = 'disponibles';
      this.handleFilterSubscriptions();
    },
    async getSubscriptionsList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await subscriptionResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.loading = false;
      this.donorsCount = this.getTypeCount('Donor');
      this.recipientsCount = this.getTypeCount('Recipient');
    },
    async getSubSubscriptionsList(subUrl) {
      this.loading = true;
      const { data } = await subscriptionResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    async getSubscriptionsStats(subUrl) {
      this.loading = true;
      const { data } = await subscriptionResource.sublist('/' + subUrl);
      this.stats = data;
      this.loading = false;
    },
    handleFilterSubscriptions() {
      this.query.page = 1;
      this.getSubscriptionsList();
    },
    handleCreateSubscription() {
      this.editing = false;
      this.resetNewSubscription();
      this.dialogCreateSubscription = true;
      /* this.$nextTick(() => {
        this.$refs['subscriptionForm'].clearValidate();
      });*/
    },
    async handleEditSubscription(id){
      this.editing = true;
      this.resetNewSubscription();
      this.currentSubscription = await this.list.find(abonne => abonne.id === id);
      this.dialogCreateSubscription = true;
      /* this.$nextTick(() => {
        this.$refs['subscriptionForm'].clearValidate();
      });*/
    },
    async setSubscriptionStatus(id, type){
      const data = await subscriptionResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleDeleteSubscription(id, visible, name) {
      this.$confirm(this.$t('subscription.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['Admin']) || checkPermission(['delete subscription']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              subscriptionResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('subscription.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setSubscriptionStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createSubscription() {
      this.$refs['subscriptionForm'].validate((valid) => {
        if (valid) {
          this.currentSubscription.roles = [this.currentSubscription.role];
          this.subscriptionCreating = true;
          subscriptionResource
            .store(this.currentSubscription)
            .then(async(response) => {
              this.$message({
                message: this.$t('subscription.NewSubscription') + ' ' + this.currentSubscription.organ + ' ' + this.$t('subscription.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentSubscriptionId = response.data.id;
              this.resetNewSubscription();
              this.dialogCreateSubscription = false;
              this.handleFilterSubscriptions();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.subscriptionCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    updateSubscription() {
      this.$refs['subscriptionForm'].validate((valid) => {
        if (valid) {
          this.subscriptionCreating = true;
          subscriptionResource.update(this.currentSubscription.id, this.currentSubscription).then(response => {
            this.resetNewSubscription();
            this.handleFilterSubscriptions();
            this.$message({
              message: this.$t('subscription.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateSubscription = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.subscriptionCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewSubscription() {
      this.currentSubscription = {
        organ: '',
        description: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('user.name'), this.$t('subscription.type'), this.$t('subscription.organ'), this.$t('subscription.blod_type'), this.$t('subscription.height'), this.$t('subscription.weight')];
        const filterVal = ['id', 'name', 'type', 'organ', 'blod_type', 'height', 'weight'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Subscriptions list GIFTOLIFE_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
