<template>
  <el-card v-if="subscription.paired_name" :class="cardclass+' paired-account'">
    <div class="user-profile">
      <h4>{{ $t('subscription.paired') }} {{ subscription.type }}</h4>
      <div class="box-social">
        <div v-if="!subscription.id" class="no-discussion">
          <h3><i class="el-icon-warning" /> {{ $t('subscription.noSubscription') }} </h3>
        </div>
        <el-table v-if="subscription.id" :data="all ? allinfos : mininfos" :show-header="false">
          <el-table-column prop="name" label="Name" width="150" />
          <el-table-column label="Val" align="left">
            <template slot-scope="scope">
              {{ scope.row.val }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-row v-if="subscription.id" :gutter="20" class="text-right">
        <el-tag :type="subscription.is_active ? 'success' : 'danger'">{{ subscription.is_active ? $t('general.enabled') : $t('general.disabled') }}</el-tag>
        <el-button v-if="checkPermission(['hide subscription','delete subscription'])" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteSubscription(subscription.id, subscription.is_visible, subscription.paired_name);" />
      </el-row>
    </div>
  </el-card>
</template>

<script>
import Resource from '@/api/resource';
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
const subscriptionResource = new Resource('subscriptions');
export default {
  props: {
    subscription: {
      type: Object,
      default: () => {
        return {
          blod_type: '',
          organ: '',
          paired_name: '',
          gender: '',
          age: '',
          type: '',
        };
      },
    },
    cardclass: {
      type: String,
      default: 'subscriber-card',
    },
    all: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mininfos: [
        {
          'name': this.$t('subscription.blod_type'),
          'val': this.subscription.blod_type,
        },
        {
          'name': this.$t('subscription.organ'),
          'val': this.subscription.organ,
        },
      ],
      allinfos: [
        {
          'name': this.$t('user.name'),
          'val': this.subscription.paired_name,
        },
        {
          'name': this.$t('subscription.gender'),
          'val': this.subscription.gender,
        },
        {
          'name': this.$t('subscription.age'),
          'val': this.subscription.age,
        },
        {
          'name': this.$t('subscription.blod_type'),
          'val': this.subscription.blod_type,
        },
        {
          'name': this.$t('subscription.organ'),
          'val': this.subscription.organ,
        },
        {
          'name': this.$t('subscription.travel'),
          'val': this.subscription.travel ? this.$t('general.yes') : this.$t('general.no'),
        },
      ],
    };
  },
  watch: { // It listens to the change in prop name
    subscription: function() {
      this.allinfos = [
        {
          'name': this.$t('user.name'),
          'val': this.subscription.paired_name,
        },
        {
          'name': this.$t('subscription.gender'),
          'val': this.subscription.gender,
        },
        {
          'name': this.$t('subscription.age'),
          'val': this.subscription.age,
        },
        {
          'name': this.$t('subscription.blod_type'),
          'val': this.subscription.blod_type,
        },
        {
          'name': this.$t('subscription.organ'),
          'val': this.subscription.organ,
        },
        {
          'name': this.$t('subscription.travel'),
          'val': this.subscription.travel ? this.$t('general.yes') : this.$t('general.no'),
        },
      ];
      this.mininfos = [
        {
          'name': this.$t('subscription.blod_type'),
          'val': this.subscription.blod_type,
        },
        {
          'name': this.$t('subscription.organ'),
          'val': this.subscription.organ,
        },
      ]; // print out when the name changes
    },
  },
  methods: {
    checkPermission,
    checkRole,
    handleDeleteSubscription(id, visible, name) {
      this.$confirm(this.$t('subscription.deleteWarn') + ' ' + name + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        if (!visible && (checkRole(['Admin']) || checkPermission(['delete subscription']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              subscriptionResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('subscription.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setSubscriptionStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.el-table tr {
    background-color: aliceblue !important;
}

.half-right {
    width: 70% !important;
    float: none !important;
    text-align: left !important;
    padding: 10px;
}
.user-profile {
  .user-name {
    font-weight: bold;
  }
  .box-center {
    padding-top: 10px;
  }
  .user-role {
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
  }
  .box-social {
    padding-top: 30px;
    .el-table {
      border-top: 1px solid #dfe6ec;
    }
  }
  .user-follow {
    padding-top: 20px;
  }
}
</style>
